/**
 * Run PMW main loader
 */
import {loadDeprecatedFunctions} from "./wpm/deprecated.mjs"

const main = async () => {

	require("./wpm/functions_main")
	require("./version.js")

	// Wait until jQuery is loaded
	await wpm.jQueryExists()

	// Wait until wpmDataLayer is loaded
	await wpm.wpmDataLayerFullyLoaded()

	// wait 1000ms
	// await new Promise(resolve => setTimeout(resolve, 1000))

	// Load all essential scripts first
	require("./wpm/functions_loader")

	// Output PMW console message
	wpm.pmwConsoleMessage()

	// Load the consent management module
	await wpm.consent.load()

	// Doesn't work if we listen to pmw:dom:ready because it runs too late
	// (at the end of the main.js file)
	// TODO: Find out why that order is important and optimize it. It is also difficult to test. In Cypress it works just with "pmw:dom:ready"
	jQuery(() => {
		require("./wpm/event_listeners_on_ready")
	})

	// Load remaining event listeners
	require("./wpm/event_listeners")
	require("./wpm/listeners/doofinder")

	wpm.loadWcHooksFunctions()

	// Check if domain is excluded from tracking
	// If so, return
	if (wpm.excludeDomainFromTracking()) return

	// Load free pixels
	require("./pixels/google/loader")
	require("./pixels/facebook/loader")
	require("./pixels/hotjar/loader")

	// #if process.env.TIER === 'premium'
	/**
	 *  Load all premium scripts
	 */
	if (wpm.canLoadPremiumFeatures()) {
		require("./wpm/event_listeners_premium")
		require("./pixels/adroll/loader")
		require("./pixels/linkedin/loader")
		require("./pixels/microsoft-ads/loader")
		require("./pixels/outbrain/loader")
		require("./pixels/pinterest/loader")
		require("./pixels/snapchat/loader")
		require("./pixels/taboola/loader")
		require("./pixels/tiktok/loader")
		require("./pixels/twitter/loader")
		require("./pixels/reddit/loader")
		require("./pixels/vwo/loader")
	}
	// #endif

	/** Load the deprecated functions */
	loadDeprecatedFunctions()

	// Preload pixels
	document.dispatchEvent(new Event("pmw:load-pixels"))

	// Trigger wpmLoad event
	document.dispatchEvent(new Event("wpmLoad"))

	// Wait until page is loaded
	await wpm.pageLoaded()

	document.dispatchEvent(new Event("pmw:ready"))
	wpm.triggerPmwDomReadyEvent()

	// Check if the library version matches the PMW version that's installed on the server
	wpm.checkLibraryVersion()
}

main()








