/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Reddit event listeners
 *
 * Basic info: https://redditinc.force.com/helpcenter/s/article/Install-the-Reddit-Pixel-on-your-website
 * Advanced matching: https://redditinc.force.com/helpcenter/s/article/Reddit-Pixel-Advanced-Matching
 * Event metadata: https://redditinc.force.com/helpcenter/s/article/Reddit-Pixel-Event-Metadata
 * */

// Pixel load event listener
jQuery(document).on("pmw:load-pixels", () => {
	if (wpmDataLayer?.pixels?.reddit?.advertiser_id && !wpmDataLayer?.pixels?.reddit?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Reddit")) wpm.loadRedditPixel();
	}
});

// AddToCart event
jQuery(document).on("pmw:add-to-cart", (event, product) => {

	try {
		if (wpm.canNotFireRedditEvent()) return;

		let data = {
			currency : product.currency,
			itemCount: product.quantity,
			value    : product.price * product.quantity,
			products : [{
				id      : wpm.getProductIdForSpecificPixel(product, "reddit"),
				category: product.category,
				name    : product.name,
			}],
		};

		rdt("track", "AddToCart", data);
		pmw.console.log("Reddit Pixel: AddToCart event sent", data);

	} catch (e) {
		console.error(e);
	}
});

// ViewContent event
jQuery(document).on("pmw:view-item", (event, product = null) => {

	try {
		if (wpm.canNotFireRedditEvent()) return;

		rdt("track", "ViewContent");
		pmw.console.log("Reddit Pixel: ViewContent event sent");

	} catch (e) {
		console.error(e);
	}
});

// view search event
jQuery(document).on("pmw:search", () => {

	try {
		if (wpm.canNotFireRedditEvent()) return;

		rdt("track", "Search");
		pmw.console.log("Reddit Pixel: search event sent");

	} catch (e) {
		console.error(e);
	}
});

jQuery(document).on("pmw:add-to-wishlist", (event, product) => {

	try {
		if (wpm.canNotFireRedditEvent()) return;

		let data = {
			currency : product.currency,
			itemCount: product.quantity,
			value    : product.price * product.quantity,
		};

		rdt("track", "AddToWishlist", data);
		pmw.console.log("Reddit Pixel: AddToWishlist event sent", data);

	} catch (error) {
		console.error(error);
	}
});

// view order received page event
jQuery(document).on("pmw:view-order-received-page", () => {

	try {
		if (wpm.canNotFireRedditEvent()) return;

		let data = {
			conversionId: wpmDataLayer.order.number,
			currency    : wpmDataLayer.order.currency,
			value       : wpmDataLayer.order.value.marketing,
			products    : wpm.redditGetProductsFromCart(),
		};

		// Get order item count
		// Go through each order item and sum up the quantity
		if (wpmDataLayer.order.items) {
			let itemCount = 0;
			wpmDataLayer.order.items.forEach(item => {
				itemCount += parseInt(item.quantity);
			});

			data.itemCount = itemCount;
		}

		rdt("track", "Purchase", data);
		pmw.console.log("Reddit Pixel: Purchase event sent", data);

	} catch (e) {
		console.error(e);
	}
});
