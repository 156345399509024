// https://ads.tiktok.com/help/article?aid=10028
// Deduplication: https://ads.tiktok.com/marketing_api/docs?id=1723170195197953
// TODO check all events and add more if there are any

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load TikTok Ads event listeners
 *
 * Source: https://ads.tiktok.com/help/article/supported-standard-events
 * */

// Pixel load event listener
jQuery(document).on("pmw:load-pixels", () => {

	if (wpmDataLayer?.pixels?.tiktok?.pixel_id && !wpmDataLayer?.pixels?.tiktok?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "TikTok")) wpm.loadTikTokPixel()
	}
})

const handleTikTokEvent = (eventName, event, payload) => {
	try {
		if (wpm.canNotFireTikTokEvent()) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)

		pmw.console.log(`TikTok Pixel: ${payload.tiktok.event} event sent`, payload.tiktok)

	} catch (e) {
		console.error(e)
	}
}

const tikTokEvents = [
	"pmw:s2s:view-item",
	"pmw:s2s:add-to-cart",
	"pmw:s2s:add-to-wishlist",
	"pmw:s2s:search",
	"pmw:s2s:begin-checkout",
	"pmw:s2s:add-payment-info",
	"pmw:s2s:place-order",
	"pmw:s2s:view-order-received-page",
]

tikTokEvents.forEach(eventName => {
	jQuery(document).on(eventName, handleTikTokEvent.bind(null, eventName))
})
